import { memo } from 'react';
import MainLottieLoader from './MainLottieLoader';
import { PageLoaderContainer } from './PageLoaderStyle';

const PageLoader = () => {
  return (
    <PageLoaderContainer>
      <MainLottieLoader />
      {/* <LoaderCircle height="60px" width="60px" /> */}
    </PageLoaderContainer>
  );
};

export default memo(PageLoader);
