import router from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import Link from 'next/dist/client/link';
import { COLORS } from '../../constants/colors';
import { useAppSelector } from '../../redux/hooks';
import { MaskIcon } from '../Icons/Icons';
import ProfileSidebar from '../ProfileSidebar/ProfileSidebar';
import SellerSettingSidebar from '../SellerSettingsSidebar/SellerSettingSidebar';
import SellerSidebar from '../SellerSidebar/SellerSidebar';
import {
  AlertNotificationDot,
  NavButtons,
  NavButtonTitle,
  NavigationContainer,
  NavTitle,
} from './UserDashboardNavStyles';
import { useLocale } from '../../contexts/LocalizationContext';

interface Props {
  seller: boolean;
}

const UserDashboardNav = (props: Props) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { user } = useAppSelector((state) => state.user);
  let {messages} = useLocale();
  const {my_coupons_msg,my_orders_msg,my_library_msg,my_profile_msg,seller_settings_msg} = messages||{};

  const [showProfileSubOption, setShowProfileSubOption] =
    useState<boolean>(false);
  const [showStoreSubOption, setShowStoreSubOption] = useState<boolean>(false);
  const [showSellerSettingsSubOption, setShowSellerSettingsSubOption] =
    useState<boolean>(false);

  const handleProfileSubOption = useCallback(() => {
    setShowProfileSubOption(!showProfileSubOption);
  }, [showProfileSubOption]);

  const handleStoreSubOption = useCallback(() => {
    setShowStoreSubOption(!showStoreSubOption);
  }, [showStoreSubOption]);

  const handleSellerSubOption = useCallback(() => {
    setShowSellerSettingsSubOption(!showSellerSettingsSubOption);
  }, [showSellerSettingsSubOption]);

  useEffect(() => {
    if (router.pathname.includes('/user/profile')) {
      setCurrentTab(0);
      handleProfileSubOption();
      return;
    }

    if (router.pathname === '/user/my-coupons') {
      setCurrentTab(1);
      return;
    }

    if (router.pathname === '/user/library') {
      setCurrentTab(2);
      return;
    }

    if (router.pathname.includes('/user/orders')) {
      setCurrentTab(3);
      return;
    }

    if (router.pathname.includes('/user/settings')) {
      setCurrentTab(4);
      return;
    }

    if (
      router.pathname.includes('/user/selling') &&
      !router.pathname.includes('/user/selling/notifications') &&
      !router.pathname.includes('/user/selling/api-settings') &&
      !router.pathname.includes('/user/selling/surcharge') &&
      !router.pathname.includes('/user/selling/declared-stock')
    ) {
      setCurrentTab(5);
      handleStoreSubOption();
      return;
    }
    if (
      router.pathname.includes('/user/selling') &&
      (router.pathname.includes('/user/selling/notifications') ||
        router.pathname.includes('/user/selling/api-settings') ||
        router.pathname.includes('/user/selling/surcharge') ||
        router.pathname.includes('/user/selling/declared-stock'))
    ) {
      setCurrentTab(6);
      handleSellerSubOption();
      return;
    }
  }, []);

  return (
    <NavigationContainer>
      <NavButtons isActive={currentTab === 0 || showProfileSubOption}>
        <NavButtonTitle
          isActive={currentTab === 0}
          onClick={() => {
            setCurrentTab(0);
          }}
        >
          <Link passHref href="/user/profile">
            <a>
              <NavTitle isActive={currentTab === 0}>
                <MaskIcon
                  url="/icons/face-24.svg"
                  width="24px"
                  height="24px"
                  selected={true}
                  color={COLORS.white}
                />
                <span>{my_profile_msg}</span>
              </NavTitle>
            </a>
          </Link>

          <MaskIcon
            url="/icons/arrow-down-chevron-24.svg"
            width="24px"
            height="24px"
            selected={true}
            margin={'0 0 0 auto'}
            color={COLORS.white}
            onClick={(e) => {
              e.stopPropagation();
              handleProfileSubOption();
            }}
            style={{
              zIndex: 10,
              transform: showProfileSubOption ? 'rotate(180deg)' : 'rotate(0)',
            }}
          />
        </NavButtonTitle>

        {showProfileSubOption && <ProfileSidebar />}
      </NavButtons>

      <Link passHref href="/user/my-coupons">
        <a>
          <NavButtons
            isActive={currentTab === 1}
            onClick={() => {
              setCurrentTab(1);
              // router.push('/user/my-coupons');
            }}
          >
            <NavButtonTitle isActive={currentTab === 1}>
              <MaskIcon
                url="/icons/coupon-24.svg"
                width="24px"
                height="24px"
                selected={true}
                color={COLORS.white}
              />
              <span>{my_coupons_msg}</span>
            </NavButtonTitle>
          </NavButtons>
        </a>
      </Link>
      <Link passHref href="/user/library">
        <a>
          <NavButtons
            isActive={currentTab === 2}
            onClick={() => {
              setCurrentTab(2);
              // router.push('/user/library');
            }}
          >
            <NavButtonTitle isActive={currentTab === 2}>
              <MaskIcon
                url="/icons/controller-24.svg"
                width="24px"
                height="24px"
                selected={true}
                color={COLORS.white}
              />
              <span>{my_library_msg}</span>
            </NavButtonTitle>
          </NavButtons>
        </a>
      </Link>
      <Link passHref href="/user/orders">
        <a>
          <NavButtons
            isActive={currentTab === 3}
            onClick={() => {
              setCurrentTab(3);
              // router.push('/user/orders');
            }}
          >
            <NavButtonTitle isActive={currentTab === 3}>
              <MaskIcon
                url="/icons/cart-24.svg"
                width="24px"
                height="24px"
                selected={true}
                color={COLORS.white}
              />
              <span>{my_orders_msg}</span>
            </NavButtonTitle>
          </NavButtons>
        </a>
      </Link>
      {/* <Link passHref href="/user/settings">
        <a>
          <NavButtons
            isActive={currentTab === 4}
            onClick={() => {
              setCurrentTab(4);
              // router.push('/user/orders');
            }}
          >
            <NavButtonTitle isActive={currentTab === 4}>
              <MaskIcon
                url="/icons/category-icons/settings.svg"
                width="24px"
                height="24px"
                selected={true}
                color={COLORS.white}
              />
              <span>Settings</span>
            </NavButtonTitle>
          </NavButtons>
        </a>
      </Link> */}
      {user?.storeDetails?.store_status === 1 && (
        <NavButtons isActive={currentTab === 5 || showStoreSubOption}>
          <NavButtonTitle
            isActive={currentTab === 5}
            onClick={() => {
              setCurrentTab(5);
            }}
          >
            <Link passHref href="/user/selling/dashboard">
              <a>
                <NavTitle isActive={currentTab === 5}>
                  <MaskIcon
                    url="/icons/store.svg"
                    width="24px"
                    height="24px"
                    selected={true}
                    color={COLORS.white}
                  />
                  <span>My Store</span>
                </NavTitle>
              </a>
            </Link>
            <MaskIcon
              url="/icons/arrow-down-chevron-24.svg"
              width="24px"
              height="24px"
              selected={true}
              margin={'0 0 0 auto'}
              color={COLORS.white}
              onClick={(e) => {
                e.stopPropagation();
                handleStoreSubOption();
              }}
              style={{
                zIndex: 10,
                transform: showStoreSubOption ? 'rotate(180deg)' : 'rotate(0)',
              }}
            />
          </NavButtonTitle>
          {/* <SellerSidebar seller={props.seller ? true : false} /> */}

          {showStoreSubOption && <SellerSidebar />}
        </NavButtons>
      )}
      {user?.storeDetails?.store_status === 1 && (
        <NavButtons
          isActive={currentTab === 6 || showStoreSubOption}
          onClick={handleSellerSubOption}
        >
          <NavButtonTitle
            isActive={currentTab === 6}
            onClick={() => {
              setCurrentTab(6);
            }}
          >
            {user && user.storeDetails?.features_allowed?.declaredStock ? (
              <AlertNotificationDot>1</AlertNotificationDot>
            ) : null}
            {/* <Link passHref href="/user/selling/api-settings">
              <a> */}
            <NavTitle isActive={currentTab === 6}>
              <MaskIcon
                url="/icons/category-icons/settings.svg"
                width="24px"
                height="24px"
                selected={true}
                color={COLORS.white}
              />
              <span>{seller_settings_msg}</span>
            </NavTitle>
            {/* </a>
            </Link> */}
            <MaskIcon
              url="/icons/arrow-down-chevron-24.svg"
              width="24px"
              height="24px"
              selected={true}
              margin={'0 0 0 auto'}
              color={COLORS.white}
              style={{
                zIndex: 10,
                transform: showSellerSettingsSubOption
                  ? 'rotate(180deg)'
                  : 'rotate(0)',
              }}
            />
          </NavButtonTitle>
          {/* <SellerSidebar seller={props.seller ? true : false} /> */}

          {showSellerSettingsSubOption && <SellerSettingSidebar />}
        </NavButtons>
      )}
    </NavigationContainer>
  );
};

export default UserDashboardNav;
