import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { NewTileContainer } from '../../user/components/SideNavigation/SideNavvigationStyles';
import { sellerSettings } from '../DashboardSidebar/const';
import {
  IsSelectedIcon,
  SubOption,
  SubOptionTitle,
  SubOptionsWrapper,
} from '../UserDashboardNav/UserDashboardNavStyles';
interface Props {
  type?: 'mobile';
  seller?: boolean;
}

const SellerSettingSidebar = (props: Props) => {
  const { user } = useAppSelector((state) => state);
  const router = useRouter();

  const [selected, setSelected] = useState<number | null>(null);
  const [items, setItems] = useState<any[]>([]);

  function matchURL(path: string, url: string): boolean {
    if (path === url) return true;
    if (path !== '/user' && url === '/user') return false;
    return path.includes(url);
  }
  useEffect(() => {
    sellerSettings.forEach((item, index) => {
      if (matchURL(router.asPath, item.url)) {
        setSelected(index);
      }
    });
  }, [router]);

  useEffect(() => {
    if (user.user?.storeDetails?.features_allowed?.vat) {
      setItems(sellerSettings);
    } else {
      setItems(
        sellerSettings.filter((cur) => cur.name !== 'Surcharge Settings')
      );
    }
    if (!!!user.user?.storeDetails?.features_allowed?.apiSelling) {
      setItems(sellerSettings.filter((cur) => cur.name !== 'API Settings'));
    }
    if (!!!user.user?.storeDetails?.features_allowed?.declaredStock) {
      setItems(sellerSettings.filter((cur) => cur.name !== 'Declared Stock'));
    }
  }, [user]);

  // const handleScrollSelectedOption = () => {
  //   const el = document.getElementById(`dashboard-sidebar-${selected}`);
  //   const offsetX = el?.offsetLeft;
  //   containerRef.current?.scrollTo(offsetX || 0, 0);
  // };

  // useEffect(() => {
  //   handleScrollSelectedOption();
  // }, [selected, width]);

  return (
    <SubOptionsWrapper>
      {(items || []).map(
        (item, index) =>
          !item.sellItem && (
            <Link key={index} passHref href={item.url}>
              <a>
                <SubOption
                  onClick={(e: any) => {
                    e.stopPropagation();
                    // router.push(item.url);
                  }}
                >
                  <IsSelectedIcon show={matchURL(router.asPath, item.url)} />
                  <SubOptionTitle
                    isSelected={matchURL(router.asPath, item.url)}
                  >
                    {item.name}{' '}
                    {item.name === 'Declared Stock' ? (
                      <NewTileContainer>New</NewTileContainer>
                    ) : null}
                  </SubOptionTitle>
                </SubOption>
              </a>
            </Link>
          )
      )}
    </SubOptionsWrapper>
  );
};

export default SellerSettingSidebar;
