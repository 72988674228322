import React, { ReactNode } from 'react';
import { useTheme } from 'styled-components';
import withAuth from '../HOC/withAuth';
import { Container } from '../components/CurrentlySelling/CurrentlySellingStyle';
import Header from '../components/Header/Header';
import MobileHeader from '../components/MobileHeader/MobileHeader';
import MobileNav from '../components/MobileNav/MobileNav';
import UserDashboardNav from '../components/UserDashboardNav/UserDashboardNav';
import { WIDTH } from '../constants/screenResolution';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { BodyContainer } from '../styles/pageStyles/userPagesStyle';
import { getNumFromResolutionConstant } from '../utils/getNumberFromResolutionConstant';
interface Props {
  children: ReactNode;
  seller?: boolean;
  showDashboardNav?: boolean;
  handleInputSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UserLayout = ({ children, showDashboardNav = true, ...props }: Props) => {
  const { width } = useWindowDimensions();
  const theme = useTheme();
  return (
    <div style={{ background: theme.palette.background.bg2 }}>
      <Header type="user" />
      <MobileHeader />
      <BodyContainer>
        {width &&
        width > getNumFromResolutionConstant(WIDTH.tabletMax) &&
        showDashboardNav ? (
          <UserDashboardNav seller={props.seller ? true : false} />
        ) : null}
        <Container>{children}</Container>
      </BodyContainer>
      <MobileNav />
    </div>
  );
};

export default withAuth(UserLayout);
