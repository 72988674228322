import { apiConstants } from '../../constants/apiConstants';
import { trackEvent } from '../../utils/eventTracking';
import { BackgroundIcon, MaskIcon, SteamIconNew } from '../Icons/Icons';
// import { RegisterFormMessages } from './messages';
import { useTheme } from 'styled-components';
import { useESim } from '../../contexts/ESimContext';
import { useLocale } from '../../contexts/LocalizationContext';
import { WE_USER_EVENT_SOCIAL_LOGIN_INITIATE } from '../../utils/we';
import { ButtonContainer, ButtonLabel } from './SocialLoginStyle';

interface ButtonContainerInterface {
  type: 'google' | 'facebook' | 'discord' | 'steam';
  children: JSX.Element | JSX.Element[] | string | string[];
}
export const SocialButtonContainer = (props: ButtonContainerInterface) => {
  const onSocialClick = (type: string) => {
    const eventData = {
      event: 'login',
      method: type,
    };
    trackEvent('gtm_event', { eventData });

    WE_USER_EVENT_SOCIAL_LOGIN_INITIATE(type);

    window.open(
      `${apiConstants.server}/social-auth/${type}`,
      'mywindow1234567890',
      'popup, width=600, height=600'
    );
  };

  return (
    <ButtonContainer onClick={() => onSocialClick(props.type)}>
      {props.children}
    </ButtonContainer>
  );
};

interface Props {
  type: 'Login' | 'Sign up';
}

export const GoogleLogin = (props: Props) => {
  const { messages } = useLocale();
  const { continue_with_msg } = messages || {};

  return (
    <SocialButtonContainer type="google">
      <BackgroundIcon
        url="/icons/google-icon.svg"
        height="21px"
        width="21px"
        margin="0 0 0 0"
      />
      <ButtonLabel>
        <span>{continue_with_msg}</span> Google
      </ButtonLabel>
    </SocialButtonContainer>
  );
};

export const FacebookLogin = (props: Props) => {
  const { messages } = useLocale();
  const { continue_with_msg } = messages || {};

  return (
    <SocialButtonContainer type="facebook">
      <BackgroundIcon
        url="/icons/facebook-round-icon.svg"
        height="21px"
        width="21px"
        margin="0 0 0 0"
      />
      <ButtonLabel>
        <span>{continue_with_msg}</span> Facebook
      </ButtonLabel>
    </SocialButtonContainer>
  );
};

export const SteamLogin = (props: Props) => {
  return (
    <SocialButtonContainer type="steam">
      <SteamIconNew />
      {/* <ButtonLabel>{props.type} with Steam</ButtonLabel> */}
    </SocialButtonContainer>
  );
};

export const DiscordLogin = (props: Props) => {
  const { messages } = useLocale();
  const { continue_with_msg } = messages || {};

  return (
    <SocialButtonContainer type="discord">
      <BackgroundIcon
        url="/icons/discord-icon-new.svg"
        height="21px"
        width="21px"
        margin="0 0 0 0"
      />
      <ButtonLabel>
        <span>{continue_with_msg}</span> Discord
      </ButtonLabel>
    </SocialButtonContainer>
  );
};
interface MailLoginProps extends Props {
  changeSocial: () => any;
  checkout?: boolean;
}

export const MailLogin = (props: MailLoginProps) => {
  const { messages } = useLocale();
  const { isESim } = useESim();
  const { continue_with_msg } = messages || {};

  const theme = useTheme();

  return (
    <ButtonContainer onClick={() => props.changeSocial()}>
      <MaskIcon
        url="/icons/email-black.svg"
        height="21px"
        width="21px"
        margin="0 0 0 0"
        color={theme.palette.text.t1}
      />
      <ButtonLabel>
        <span>{continue_with_msg} </span>
        {props.checkout && isESim ? 'Others' : 'Email'}
      </ButtonLabel>
    </ButtonContainer>
  );
};
