import { COLORS } from '../../constants/colors';
import { MaskIcon } from '../Icons/Icons';

export const sellerItems = [
  {
    id: 1,
    name: 'Dashboard',
    icon: (s: boolean) => (
      <MaskIcon
        url="/icons/dashboard.svg"
        color={COLORS.blackDark1}
        width="20px"
        height="20px"
        type="white"
        selected={s}
      />
    ),
    url: '/user/selling/dashboard',
  },
  {
    id: 2,
    name: 'Currently Selling',
    icon: (s: boolean) => (
      <MaskIcon
        url="/icons/selling.svg"
        color={COLORS.blackDark1}
        selected={s}
      />
    ),
    url: '/user/selling/currently-selling',
  },
  {
    id: 4,
    name: 'Transactions',
    icon: (s: boolean) => (
      <MaskIcon
        height="20px"
        width="30px"
        url="/icons/transaction.svg"
        color={COLORS.blackDark1}
        selected={s}
      />
    ),
    url: '/user/selling/transactions',
  },
  {
    id: 5,
    name: 'Withdrawals',
    icon: (s: boolean) => (
      <MaskIcon
        height="20px"
        width="30px"
        url="/icons/withdraw.svg"
        color={COLORS.blackDark1}
        selected={s}
      />
    ),
    url: '/user/selling/withdrawals',
  },

  {
    id: 6,
    name: 'Tickets',
    icon: (s: boolean) => (
      <MaskIcon
        height="20px"
        width="30px"
        url="/icons/withdraw.svg"
        color={COLORS.blackDark1}
        selected={s}
      />
    ),
    url: '/user/selling/tickets',
  },
  {
    id: 7,
    name: 'Reports',
    icon: (s: boolean) => (
      <MaskIcon
        height="20px"
        width="30px"
        url="/icons/withdraw.svg"
        color={COLORS.blackDark1}
        selected={s}
      />
    ),
    url: '/user/selling/reports',
  },
  {
    id: 8,
    name: 'Reviews',
    icon: (s: boolean) => (
      <MaskIcon
        height="20px"
        width="30px"
        url="/icons/reviews-24.svg"
        color={COLORS.blackDark1}
        selected={s}
      />
    ),
    url: '/user/selling/rating',
  },
];

export const sellerSettings = [
  {
    id: 1,
    name: 'API Settings',
    icon: (s: boolean) => (
      <MaskIcon
        height="20px"
        width="30px"
        url="/icons/withdraw.svg"
        color={COLORS.blackDark1}
        selected={s}
      />
    ),
    url: '/user/selling/api-settings',
  },
  {
    id: 2,
    name: 'Declared Stock',
    icon: (s: boolean) => (
      <MaskIcon
        height="20px"
        width="30px"
        url="/icons/declared-stock-icon.svg"
        color={COLORS.blackDark1}
        selected={s}
      />
    ),
    url: '/user/selling/declared-stock',
  },
  {
    id: 3,
    name: 'Tickets',
    icon: (s: boolean) => (
      <MaskIcon
        height="20px"
        width="30px"
        url="/icons/new-ticket-icon.svg"
        color={COLORS.blackDark1}
        selected={s}
      />
    ),
    url: '/user/selling/tickets',
  },
  {
    id: 4,
    name: 'Surcharge Settings',
    icon: (s: boolean) => (
      <MaskIcon
        height="20px"
        width="30px"
        url="/icons/withdraw.svg"
        color={COLORS.blackDark1}
        selected={s}
      />
    ),
    url: '/user/selling/surcharge',
  },
  {
    id: 5,
    name: 'Notification Settings',
    icon: (s: boolean) => (
      <MaskIcon
        height="20px"
        width="30px"
        url="/icons/withdraw.svg"
        color={COLORS.blackDark1}
        selected={s}
      />
    ),
    url: '/user/selling/notifications',
  },
];

export const profileItems = [
  {
    id: 1,
    name: 'Personal details',
    icon: (s: boolean) => (
      <MaskIcon
        url="/icons/dashboard.svg"
        color={COLORS.blackDark1}
        width="20px"
        height="20px"
        type="white"
        selected={s}
      />
    ),
    url: '/user/profile',
  },
  {
    id: 2,
    name: 'Contact details',
    icon: (s: boolean) => (
      <MaskIcon
        url="/icons/dashboard.svg"
        color={COLORS.blackDark1}
        width="20px"
        height="20px"
        type="white"
        selected={s}
      />
    ),
    url: '/user/profile/contact',
  },
  {
    id: 3,
    name: 'Password',
    icon: (s: boolean) => (
      <MaskIcon
        url="/icons/dashboard.svg"
        color={COLORS.blackDark1}
        width="20px"
        height="20px"
        type="white"
        selected={s}
      />
    ),
    url: '/user/profile/password',
  },
  {
    id: 4,
    name: '2FA',
    icon: (s: boolean) => (
      <MaskIcon
        url="/icons/dashboard.svg"
        color={COLORS.blackDark1}
        width="20px"
        height="20px"
        type="white"
        selected={s}
      />
    ),
    url: '/user/profile/twoFA',
  },
];
export const items = [
  {
    id: 2,
    name: () => 'My Profile',
    url: '/user/profile',
    icon: (selected: boolean) => (
      <MaskIcon url="/icons/profile-dashboard.svg" selected={selected} />
    ),
  },
  {
    id: 3,
    name: () => 'My Library',
    url: '/user/library',
    icon: (selected: boolean) => (
      <MaskIcon url="/icons/gamepad.svg" selected={selected} />
    ),
  },
  {
    id: 4,
    name: () => 'My Orders',
    icon: (selected: boolean) => (
      <MaskIcon url="/icons/my_purchases.svg" selected={selected} />
    ),
    url: '/user/orders',
  },
  {
    id: 5,
    name: (seller: boolean) => {
      return seller ? 'My Store' : '';
    },
    url: '/user/selling',
    icon: (selected: boolean) => (
      <MaskIcon url="/icons/store.svg" selected={selected} />
    ),
  },
  {
    id: 6,
    name: () => {
      return 'Seller Settings';
    },
    url: '/user/selling/api-settings',
    icon: (selected: boolean) => (
      <MaskIcon url="/icons/settings.svg" selected={selected} />
    ),
  },
  // {
  //   id: 6,
  //   name: 'Currently Selling',
  //   url: '/user/currentlyselling',
  //   icon: <HomeWhiteSmallIcon />,
  // },
  // {
  //   id: 7,
  //   name: 'Edit Product',
  //   url: '/user/editproduct',
  //   icon: <HomeWhiteSmallIcon />,
  // },
  // {
  //   id: 8,
  //   name: 'Notifications',
  //   url: '/user/notifications',
  //   icon: <HomeWhiteSmallIcon />,
  // },
];

export default items;
