import styled from 'styled-components';

import { COLORS } from '../../constants/colors';

export const DashboardContainer = styled.div`
  width: 100%;
  margin: auto 0;
  padding: 0 16px;
`;

export const ProfilePhotoContainer = styled.div`
  & > div {
    margin: 0;
  }
`;

export const Header = styled.div`
  display: grid;
  width: fit-content;
  grid-template-columns: 1fr;
  place-content: center;
  place-items: center;
  margin: 0 auto;
  margin-bottom: 32px;
`;

export const Title = styled.p`
  font-size: 24px;
  line-height: 24px;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  margin: 0;
  margin-top: 16px;
`;

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
`;

export const ItemContainer = styled.div`
  background: ${(props) => props.theme.palette.background.bg1};
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.s1};
  display: flex;
  align-items: start;
  gap: 16px;
  height: 70px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    transform: scale(0.95);
  }
`;

export const ItemContentContainer = styled.div``;

export const ItemTitle = styled.p`
  font-size: 16px;
  line-height: 19.2px;
  font-family: Onest-Bold;
  margin-bottom: 2px;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const ItemSubtitle = styled.p`
  font-size: 14px;
  line-height: 16.8px;
  font-family: Onest-Medium;

  color: ${(props) => props.theme.palette.text.t3};
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
`;

export const LogoutButton = styled.button`
  width: 100%;
  padding: 17px;
  background: ${COLORS.gray1};
  color: ${COLORS.black};
  border-radius: 8px;
  margin-bottom: 24px;
  font-size: 16px;
  font-family: Onest-Bold;
  &:hover {
    background: ${COLORS.grey};
  }
`;
