/* eslint-disable react/display-name */
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PageLoader from '../components/PageLoader/PageLoader';
import { useESim } from '../contexts/ESimContext';
import { AuthContext } from '../user/context/AuthContext';

// const withAuth = (WrappedComponent: any) => {
//   return (props: any) => {
//     // checks whether we are on client / browser or server.
//     if (typeof window !== 'undefined') {
//       const Router = useRouter();
//       const { user } = useAppSelector((state) => state.user);
//       if (!user) {
//         Router.replace('/');
//         return null;
//       }
//       return <WrappedComponent {...props} />;
//     }

//     // If we are on server, return null
//     return null;
//   };
// };

const withAuth = (WrappedComponent: any) => (props: any) => {
  const { isESim } = useESim();
  const [authenticated, setAuthenticated] = useState<
    'loading' | 'unauth' | 'auth'
  >('loading');

  const { auth } = useContext(AuthContext);

  useEffect(() => {
    if (auth === 'auth') {
      setAuthenticated('auth');
    } else if (auth === 'unauth') {
      setAuthenticated('unauth');
    }
  }, [auth]);

  // useEffect(() => {
  //   if (user !== undefined) {
  //     axios
  //       .get(apiConstants.server + '/public/customer/auth', {
  //         withCredentials: true,
  //       })
  //       .then(() => {
  //         setAuthenticated('auth');
  //       })
  //       .catch(() => {
  //         setAuthenticated('unauth');
  //       });
  //   }
  // }, [user]);

  if (typeof window !== 'undefined') {
    const router = useRouter();
    if (authenticated === 'loading') {
      return (
        <>
          <WrappedComponent {...props} />;
        </>
      );
      // (
      // <LoadingBg>
      //   <LoadingImageContainer>
      //     <Image
      //       src={`/images/fetching-product-loader-${
      //         theme.mode === 'dark' ? 'dark' : 'light'
      //       }.gif`}
      //       width="40px"
      //       height="28px"
      //       alt="fetching gif"
      //     />
      //   </LoadingImageContainer>
      //   <WrappedComponent {...props} />;
      //   <LoadingBackdrop />
      // </LoadingBg>
      // );
    } else if (authenticated === 'unauth') {
      let routerQuery =
        router.query.redirect == '0'
          ? {
              login: 1,
            }
          : {
              login: 1,
              redirectionUrl: encodeURIComponent(window.location.href),
            };

      router.push({
        ...router,
        pathname: isESim ? '/esim' : '/',
        query: {
          ...router.query,
          ...routerQuery,
        },
      });

      return (
        <>
          <PageLoader />
        </>
      );
    } else {
      return <WrappedComponent {...props} />;
    }
  } else {
    return null;
  }
};

export default withAuth;

const LoadingBg = styled.div`
  position: relative;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  overflow: auto;
`;

export const LoadingImageContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
`;

export const LoadingBackdrop = styled.div`
  position: absolute;
  z-index: 98;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  overflow: hidden;
`;
