import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const NavigationContainer = styled.nav`
  padding: 16px;
  background: #2e2e2e;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  gap: 12px;
  width: 328px;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  box-shadow: ${(props) => props.theme.shadows.s1};
  position: sticky;
  top: 90px;
  height: calc(100vh - 106px);
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-thumb {
    background: #646464;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-thumb:hover {
    border-radius: 30px;
  }
  & a {
    width: 100%;
  }
`;

export const NavTitle = styled.div<{ isActive: boolean }>`
  display: flex;
  gap: 8px;

  align-items: center;
  font-size: 16px;
  > span {
    font-family: ${(props) => (props.isActive ? 'Onest-Bold' : 'Onest-Medium')};
  }
  color: ${COLORS.white};
`;
export const NavButtons = styled.button<{ isActive: boolean }>`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  transition: 0.3s;
  background: ${(props) => (props.isActive ? '#434343' : 'transparent')};
  &:hover {
    background: #434343;
  }
`;

export const NavButtonTitle = styled.div<{ isActive: boolean }>`
  display: flex;
  gap: 8px;
  position: relative;
  align-items: center;
  font-size: 1rem;
  width: 100%;

  line-height: 1.125rem;
  > span {
    font-family: ${(props) => (props.isActive ? 'Onest-Bold' : 'Onest-Medium')};
  }
  color: ${COLORS.white};
`;

export const SubOptionsWrapper = styled.div`
  padding: 12px 0 0 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SubOption = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

export const IsSelectedIcon = styled.div<{ show: boolean }>`
  background: ${(props) => (props.show ? '#646464' : '#00000000')};
  width: ${(props) => (props.show ? '6px' : '6px')};
  height: 20px;
  border-radius: 6px;
  transition: 0.3s all ease;
`;

interface SubOptionTitleI {
  isSelected: boolean;
}

export const SubOptionTitle = styled.div<SubOptionTitleI>`
  color: ${COLORS.white};
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  background: ${(props) => (props.isSelected ? '#646464' : '#00000000')};
  font-family: ${(props) => (props.isSelected ? 'Onest-Bold' : 'Onest-Medium')};
  padding: 12px 0 12px 12px;
  :hover {
    background: #646464;
  }
`;

export const AlertNotificationDot = styled.div`
  position: absolute;
  top: -4px;
  left: -4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  z-index: 10;
  font: 0.675rem/0.875rem Onest-SemiBold;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.palette.primary.main};
`;
