import Lottie from 'lottie-react';
import { useTheme } from 'styled-components';
import darkLoader from '../../public/lottie/loader-black-1.json';
import lightLoader from '../../public/lottie/loader-white-1.json';

function MainLottieLoader() {
  const theme = useTheme();
  return (
    <Lottie
      animationData={theme.mode === 'dark' ? lightLoader : darkLoader}
      seamless
      loop={true}
      renderer="svg"
      style={{ height: '120px', width: '120px' }}
    />
  );
}

export default MainLottieLoader;
