import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppSelector } from '../../redux/hooks';
import { sellerItems } from '../DashboardSidebar/const';
import {
  IsSelectedIcon,
  SubOption,
  SubOptionTitle,
  SubOptionsWrapper,
} from '../UserDashboardNav/UserDashboardNavStyles';
interface Props {
  type?: 'mobile';
  seller?: boolean;
}

const SellItemContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  height: 48px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const SellerSidebar = (props: Props) => {
  const { user } = useAppSelector((state) => state);
  const router = useRouter();
  const containerRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<number | null>(null);
  const { width } = useWindowDimensions();
  const [items, setItems] = useState<any[]>([]);

  function matchURL(path: string, url: string): boolean {
    if (path === url) return true;
    if (path !== '/user' && url === '/user') return false;
    return path.includes(url);
  }
  useEffect(() => {
    sellerItems.forEach((item, index) => {
      if (matchURL(router.asPath, item.url)) {
        setSelected(index);
      }
    });
  }, [router]);

  useEffect(() => {
    if (user.user?.storeDetails?.features_allowed?.vat) {
      setItems(sellerItems);
    } else {
      setItems(sellerItems.filter((cur) => cur.name !== 'Surcharge Settings'));
    }
    if (!!!user.user?.storeDetails?.features_allowed?.apiSelling) {
      setItems(sellerItems.filter((cur) => cur.name !== 'API Settings'));
    }
    if (!!!user.user?.storeDetails?.features_allowed?.declaredStock) {
      setItems(sellerItems.filter((cur) => cur.name !== 'Declared Stock'));
    }
  }, [user]);

  // const handleScrollSelectedOption = () => {
  //   const el = document.getElementById(`dashboard-sidebar-${selected}`);
  //   const offsetX = el?.offsetLeft;
  //   containerRef.current?.scrollTo(offsetX || 0, 0);
  // };

  // useEffect(() => {
  //   handleScrollSelectedOption();
  // }, [selected, width]);

  return (
    <SubOptionsWrapper>
      {(items || []).map((item, index) => (
        <Link key={index} passHref href={item.url}>
          <a>
            <SubOption
              onClick={(e: any) => {
                e.stopPropagation();
                // router.push(item.url);
              }}
            >
              <IsSelectedIcon show={matchURL(router.asPath, item.url)} />
              <SubOptionTitle isSelected={matchURL(router.asPath, item.url)}>
                {item.name}
              </SubOptionTitle>
            </SubOption>
          </a>
        </Link>
      ))}
    </SubOptionsWrapper>
  );
};

export default SellerSidebar;
