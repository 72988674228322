import React, { useCallback, useContext } from 'react';
import { COLORS } from '../../constants/colors';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import Link from 'next/dist/client/link';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useLocale } from '../../contexts/LocalizationContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { logoutUserServer } from '../../redux/userSlice';
import { AuthContext } from '../../user/context/AuthContext';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { BackgroundIcon, MaskIcon } from '../Icons/Icons';
import MobileUserPage from '../MobileNav/MobileUserPage';
import Button from '../NewButton/Button';
import ProfilePhoto from '../UserProfile/ProfilePhoto';
import {
  DashboardContainer,
  Header,
  ItemContainer,
  ItemContentContainer,
  ItemList,
  ItemSubtitle,
  ItemTitle,
  ProfilePhotoContainer,
  Title,
} from './UserProfileDashboardStyles';

const UserProfileDashboard: React.FC = () => {
  const router = useRouter();

  const { storeUser } = useContext(AuthContext);
  const { messages } = useLocale();
  const {
    my_profile_msg,
    my_library_msg,
    logout_msg,
    edit_your_name_email_msg,
    view_coupons_msg,
    my_coupons_msg,
    view_your_collection_of_games_msg,
    my_orders_msg,
    view_your_order_history_msg,
    seller_dashboard_msg,
    sell_games_gift_msg,
    help_center_msg,
    facing_issues_here_help_msg,
  } = messages || {};

  const { width } = useWindowDimensions();
  const theme = useTheme();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { cleverTap } = useAnalytics();

  const logoutClicked = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      dispatch(logoutUserServer(router, cleverTap)).finally(() => {});
    },
    [cleverTap, dispatch, router]
  );

  if (width && width > getNumFromResolutionConstant(WIDTH.tabletMax)) {
    if (user?.isLoggedIn) {
      router.push('/user/profile');
    } else {
      router.push('/?login=1&redirect=0');
    }
  }

  return (
    <>
      {user?.isLoggedIn ? (
        <DashboardContainer>
          <Header>
            <ProfilePhotoContainer>
              <ProfilePhoto
                name={user?.user?.fullName.trim()[0] || ''}
                displayPicture={user?.user?.display_picture}
              />
            </ProfilePhotoContainer>
            <Title>{user?.user?.fullName || ''}</Title>
          </Header>
          <ItemList>
            <Link passHref href="/user/profile">
              <a>
                <ItemContainer
                // onClick={() => router.push('/user/profile')}
                >
                  <MaskIcon
                    url="/icons/profile-24.svg"
                    width="24px"
                    height="24px"
                    selected={true}
                    margin="0"
                    color={theme.palette.button.primary.main}
                  />
                  <ItemContentContainer>
                    <ItemTitle>{my_profile_msg}</ItemTitle>
                    <ItemSubtitle>{edit_your_name_email_msg}</ItemSubtitle>
                  </ItemContentContainer>
                </ItemContainer>
              </a>
            </Link>
            <Link passHref href="/user/orders">
              <a>
                <ItemContainer
                // onClick={() => router.push('/user/orders')}
                >
                  <MaskIcon
                    url="/icons/store-24.svg"
                    width="24px"
                    height="24px"
                    selected={true}
                    margin="0"
                    color={theme.palette.button.primary.main}
                  />
                  <ItemContentContainer>
                    <ItemTitle>{my_orders_msg}</ItemTitle>
                    <ItemSubtitle>{view_your_order_history_msg}</ItemSubtitle>
                  </ItemContentContainer>
                </ItemContainer>
              </a>
            </Link>
            <Link passHref href="/user/library">
              <ItemContainer
              // onClick={() => router.push('/user/library')}
              >
                <MaskIcon
                  url="/icons/controller-24.svg"
                  width="24px"
                  height="24px"
                  selected={true}
                  margin="0"
                  color={theme.palette.button.primary.main}
                />
                <ItemContentContainer>
                  <ItemTitle>{my_library_msg}</ItemTitle>
                  <ItemSubtitle>
                    {view_your_collection_of_games_msg}
                  </ItemSubtitle>
                </ItemContentContainer>
              </ItemContainer>
            </Link>

            {/* <Link passHref href="/user/my-coupons">
              <a>
                <ItemContainer
                // onClick={() => router.push('/user/my-coupons')}
                >
                  <MaskIcon
                    url="/icons/coupon-24.svg"
                    width="24px"
                    height="24px"
                    selected={true}
                    margin="0"
                    color={COLORS.purple}
                  />
                  <ItemContentContainer>
                    <ItemTitle>{my_coupons_msg}</ItemTitle>
                    <ItemSubtitle>{view_coupons_msg}</ItemSubtitle>
                  </ItemContentContainer>
                </ItemContainer>
              </a>
            </Link> */}
            <Link passHref href="/user/profile/twoFA">
              <a>
                <ItemContainer
                // onClick={() => router.push('/user/my-coupons')}
                >
                  <MaskIcon
                    url="/icons/settings.svg"
                    width="24px"
                    height="24px"
                    selected={true}
                    margin="0"
                    color={COLORS.purple}
                  />
                  <ItemContentContainer>
                    <ItemTitle>2FA</ItemTitle>
                    <ItemSubtitle>Add an extra layer of security</ItemSubtitle>
                  </ItemContentContainer>
                </ItemContainer>
              </a>
            </Link>

            {user.subscription === 'no' ? null : (
              <Link passHref href="/user/membership">
                <a>
                  <ItemContainer
                  // onClick={() => router.push('/user/my-coupons')}
                  >
                    <BackgroundIcon
                      url="/logo/driffle-plus-icon-24.svg"
                      width="24px"
                      height="24px"
                      selected={true}
                      margin="0"
                      color={COLORS.purple}
                    />
                    <ItemContentContainer>
                      <ItemTitle>
                        Driffle{' '}
                        <BackgroundIcon
                          height="20px"
                          width="32px"
                          url="/logo/plus-logo.svg"
                        />
                      </ItemTitle>
                      <ItemSubtitle>Manage your plus membership</ItemSubtitle>
                    </ItemContentContainer>
                  </ItemContainer>
                </a>
              </Link>
            )}

            {(user?.user?.storeDetails?.store_status === 1 ||
              storeUser === 'agent') && (
              <Link
                passHref
                href={`${
                  !!storeUser && storeUser === 'agent'
                    ? '/user/selling-accounts/tickets'
                    : '/user/selling/dashboard'
                }`}
              >
                <a>
                  <ItemContainer
                  // onClick={() => router.push('/user/selling/dashboard')}
                  >
                    <MaskIcon
                      url="/icons/store.svg"
                      width="24px"
                      height="24px"
                      selected={true}
                      margin="0"
                      color={theme.palette.button.primary.main}
                    />
                    <ItemContentContainer>
                      <ItemTitle>{seller_dashboard_msg}</ItemTitle>
                      <ItemSubtitle>{sell_games_gift_msg}</ItemSubtitle>
                    </ItemContentContainer>
                  </ItemContainer>
                </a>
              </Link>
            )}
            <Link passHref href="/support">
              <a>
                <ItemContainer
                // onClick={() => router.push('/support')}
                >
                  <MaskIcon
                    url="/icons/support-24.svg"
                    width="24px"
                    height="24px"
                    selected={true}
                    margin="0"
                    color={theme.palette.button.primary.main}
                  />
                  <ItemContentContainer>
                    <ItemTitle>{help_center_msg}</ItemTitle>
                    <ItemSubtitle>{facing_issues_here_help_msg}</ItemSubtitle>
                  </ItemContentContainer>
                </ItemContainer>
              </a>
            </Link>
            <Button
              buttonTheme="grey"
              height="48px"
              width="100%"
              style={{
                background: theme.palette.background.bg1,
                boxShadow: theme.shadows.s4,
              }}
              onClick={logoutClicked}
            >
              {logout_msg}
            </Button>
          </ItemList>
        </DashboardContainer>
      ) : (
        <MobileUserPage />
      )}
    </>
  );
};

export default UserProfileDashboard;
