import styled from 'styled-components';

export const PageLoaderContainer = styled.div`
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  // opacity: 0.4;
  background: ${(props) => props.theme.palette.background.bg2}80;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1301;
`;
