import React, { useContext, useEffect } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import Link from 'next/dist/client/link';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useLocale } from '../../contexts/LocalizationContext';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { useAppDispatch } from '../../redux/hooks';
import {
  openValidateEmailFormRedux,
  openValidateEmailMessageRedux,
} from '../../redux/modalSlice';
import { fetchUser, removeRedirectUrl, setError } from '../../redux/userSlice';
import { saveEventV3 } from '../../utils/eventTracking';
import { getLocalStorageString } from '../../utils/localStorageUtils';
import {
  WE_USER_EVENT_LOGIN_METHOD_SELECT,
  WE_USER_EVENT_TRACK_USER,
  WE_USER_LOGIN,
} from '../../utils/we';
import { SocialButtonContainer } from '../CustomerRegisterForm/SocialLogin';
import {
  AuthButton,
  ImageContainer,
  LoginContainer,
  SignupButtonsContainer,
} from './MobileNavStyles';

const MobileUserPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const router = useRouter();
  const { cleverTap } = useAnalytics();
  const receiveMessage = function (event: any) {
    if (event?.data?.customer) {
      dispatch(fetchUser());
      WE_USER_LOGIN(
        {
          id: event.data.customer.user_enc,
          email: event.data.customer.email,
        },
        cleverTap
      );
      WE_USER_EVENT_TRACK_USER(event.data.customer, cleverTap);

      const we_socialLogin = getLocalStorageString('we_sl');
      if (we_socialLogin) {
        WE_USER_EVENT_LOGIN_METHOD_SELECT(
          {
            method: we_socialLogin,
          },
          cleverTap
        );
      }
      saveEventV3({
        category: router.pathname === '/' ? 'home' : router.pathname,
        action: 'click',
        label: 'sign_in',
        properties: 'social',
        value: [],
        from: router,
      });
    } else if (event.data.reason === 'EMAIL_NOT_AVAILABLE') {
      dispatch(openValidateEmailFormRedux());
    } else if (event.data.reason === 'EMAIL_NOT_VERIFIED') {
      dispatch(openValidateEmailMessageRedux());
    } else {
    }
  };

  const { messages } = useLocale();
  const { access_wishlists_orders_msg, email_confirmed_msg } = messages || {};

  useEffect(() => {
    if (
      router &&
      router.isReady &&
      router.query &&
      router.query.msg === 'EMAIL_CONFIRMED'
    ) {
      setTimeout(() => {
        dispatchSnackbar({
          type: 'success',
          payload: email_confirmed_msg ?? '',
        });
      }, 3000);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(
        setError({
          isError: false,
          errorMessage: '',
        })
      );
      dispatch(removeRedirectUrl());
    };
  }, []);

  return (
    <>
      <>
        <LoginContainer>
          <ImageContainer>
            <Image
              src="	https://static.driffle.com/images/girl-using-phone.png"
              width="100px"
              height="130px"
              alt="login image"
            />
          </ImageContainer>
          <p>{access_wishlists_orders_msg}</p>
          <SignupButtonsContainer>
            <SocialButtonContainer type="google">
              <AuthButton>
                <Image
                  src="/icons/google-24.svg"
                  width="24px"
                  height="24px"
                  alt="google icon"
                />
              </AuthButton>
            </SocialButtonContainer>
            <SocialButtonContainer type="discord">
              <AuthButton>
                <Image
                  src="/icons/discord-24.svg"
                  width="24px"
                  height="24px"
                  alt="discord icon"
                />
              </AuthButton>
            </SocialButtonContainer>
            <SocialButtonContainer type="facebook">
              <AuthButton>
                <Image
                  src="/icons/facebook-24.svg"
                  width="24px"
                  height="24px"
                  alt="facebook icon"
                />
              </AuthButton>
            </SocialButtonContainer>
            <Link passHref href="/user/auth/login">
              <a>
                <AuthButton
                // onClick={() => router.push('/user/auth/login')}
                >
                  <Image
                    src="/icons/email-24.svg"
                    width="24px"
                    height="24px"
                    alt="email icon"
                  />
                </AuthButton>
              </a>
            </Link>
          </SignupButtonsContainer>
        </LoginContainer>
      </>
    </>
  );
};

export default MobileUserPage;
