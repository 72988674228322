import UserLayout from '../../Layout/UserLayout';
import UserProfileDashboard from '../../components/UserProfileDashboard/UserProfileDashboard';
import { getUserInfoSSR } from '../../utils/cf';

const User = () => {
  return (
    <div>
      <UserLayout>
        {/* <UserDashboardContainer>
          <HeadingContainer>
            <UserDashboardTitle>Dashboard</UserDashboardTitle>
          </HeadingContainer>
          <DashboardContent>
            <DashboardStepper />
          </DashboardContent>
        </UserDashboardContainer> */}
        <UserProfileDashboard />
      </UserLayout>
    </div>
  );
};
export default User;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
