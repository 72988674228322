import styled from 'styled-components';

export const ButtonContainer = styled.button`
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.background.bg1};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.t1};
  border-radius: 8px;
  font-family: Onest-Bold;
  padding: 10px 20px 10px 20px;
  width: 100%;
  height: 48px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.palette.border.b1};

  &:hover {
    background: ${(props) => props.theme.palette.button.grey.hover};
  }
`;

export const ButtonLabel = styled.div`
  font-size: 16px;
  margin-left: 24px;
  // width: 90%;
  // text-align: center;
  font-family: Onest-Bold;
  color: inherit;
  span {
    font-family: Onest-Medium;
  }
`;
