import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import { useTheme } from 'styled-components';
import { apiConstants } from '../../constants/apiConstants';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { MaskIcon } from '../Icons/Icons';
import { PhotoEditButton, ProfilePhotoContainer } from './NewUserProfileStyle';

interface ProfilePhotoProps {
  name: string;
  displayPicture?: string;
}

const Input = styled('input')({
  display: 'none',
});

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({
  name,
  displayPicture,
}) => {
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [photoUrl, setPhotoUrl] = useState<string>('');

  useEffect(() => {
    if (displayPicture) {
      setPhotoUrl(displayPicture);
    }
  }, [displayPicture]);

  const handlePhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { files } = e.target;

    const bodyFormData = new FormData();
    if (files) bodyFormData.append('displayPicture', files[0]);

    axios
      .post(
        apiConstants.server +
          '/private/customer/update-profile/display-picture',
        bodyFormData,
        {
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      .then(({ data }) => {
        setPhotoUrl(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        dispatchSnackbar({
          type: 'failure',
          payload: err?.response?.data?.msg,
        });
      });
  };

  return (
    <>
      <ProfilePhotoContainer loading={loading} displayPicture={photoUrl}>
        {name && !photoUrl
          ? name
              .split(' ')
              .slice(0, 2)
              .reduce((acc, cur) => acc + (cur[0] ? cur[0] : ''), '')
          : ''}
        <label htmlFor="icon-button-file">
          <Input
            accept="image/*"
            id="icon-button-file"
            type="file"
            onChange={handlePhotoChange}
          />
          <PhotoEditButton aria-label="upload picture">
            <MaskIcon
              url="/icons/edit-24.svg"
              width="24px"
              height="24px"
              selected={true}
              margin="0"
              color={theme.palette.text.t1}
            />
          </PhotoEditButton>
        </label>
      </ProfilePhotoContainer>
    </>
  );
};

export default ProfilePhoto;
