import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { profileItems } from '../DashboardSidebar/const';
import {
  IsSelectedIcon,
  SubOption,
  SubOptionTitle,
  SubOptionsWrapper,
} from '../UserDashboardNav/UserDashboardNavStyles';

const ProfileSidebar = () => {
  const router = useRouter();
  const [items, setItems] = useState<any[]>([]);
  function matchURL(path: string, url: string): boolean {
    if (path === url) return true;
    // console.log(path);
    if (path !== '/user' && url === '/user') return false;
    // return path.includes(url);
    return false;
  }

  useEffect(() => {
    setItems(profileItems);
  }, [profileItems]);
  return (
    <SubOptionsWrapper>
      {(items || []).map((item, index) => (
        <Link key={index} passHref href={item.url}>
          <a>
            <SubOption
              onClick={(e: any) => {
                e.stopPropagation();
                // router.push(item.url);
              }}
            >
              <IsSelectedIcon show={matchURL(router.asPath, item.url)} />
              <SubOptionTitle isSelected={matchURL(router.asPath, item.url)}>
                {item.name}
              </SubOptionTitle>
            </SubOption>
          </a>
        </Link>
      ))}
    </SubOptionsWrapper>
  );
};

export default ProfileSidebar;
